import {Routes, Route} from "react-router-dom";
import HomePage from './pages/HomePage/HomePage';
import SinglePage from './pages/SinglePage/SinglePage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage/>}></Route>
      <Route path="post" element={<SinglePage/>}></Route>
    </Routes>
  );
}

export default App;
