import React from "react"
import { Link } from "react-router-dom";
import style from "./Cards.module.css";


const Card = (props) => {
  var items = props.items;
  return(
    <div className={`${style.cards} flex flex-wrap justify-between`}>
      { items.length > 0 && 
      items.map(function(item){
        return(
          <div className={`${style.card}`} key={item.id}>
            <Link to={'post'}>
            <img className="w-full" src={item.featured_image_src_large[0]} alt="demo-img" />
            <div className="mt-4">
              <h3 className="font-bold">{item.acf.mod_name}</h3>
              <div className={`${style.card_meta_info} flex justify-between mt-0.5`}>
                <p className="text-sm"><span style={{color:"#afcaf499"}}>by</span> {item.acf.mod_author}</p>
                <div className="flex items-center">
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/download-cloud.png")} alt="download-icon"/>
                    <span className="download-count text-sm ml-0.5">241</span>
                  </div>
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/thumbs-up.png")} alt="thumbsup-icon"/>
                    <span className="like-count text-sm ml-0.5">241</span>
                  </div>
                </div>
              </div>
              <div className={`${style.cat_info} border-t mt-2 pt-2`}>
                  <p className={`${style.cat_list}`}>vhickle</p>
                  <p className={`${style.cat_list}`}>vhickle</p>
                  <p className={`${style.cat_list}`}>vhickle</p>
                  <p className={`${style.cat_list}`}>vhickle</p>
                  <p className={`${style.cat_list}`}>vhickle</p>
              </div>
            </div>
            </Link>
          </div>
       )
      })
      }    
    </div>
  )
}

export default Card;