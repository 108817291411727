import React from "react";
import styles from "./Search.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

 const SearchBox = () =>{
  return(
    <div className={`search max-w-lg mt-10`}>
      <form className={`${styles.search_form} h-16 rounded-2xl`}>
        <FontAwesomeIcon style={{color:"#ffffff"}} className="w-1/12" icon={faMagnifyingGlass} />
        <input type="text" placeholder="Search here..." className="w-7/12 h-full text-white bg-transparent border-0 outline-0" />
        <input className={`${styles.submit_btn} w-4/12 h-full rounded-lg`} type="submit" value="Search"/>
      </form>
    </div>
  )
}

export default SearchBox;