import React, {useEffect, useState} from "react";
import Header from "../../components/Header/Header";
import SearchBox from "../../components/Search/Search";
import Card from "../../components/Cards/Card";
import style from "./HomePage.module.css"

import background from "../../img/Bg.png"

const HomePage = () =>{
  

  const [getItems, SetItems] = useState([]);
  const [getPostList, SetPostList] = useState(1);
  const [getCurrentPostList, SetCurrentPostList] = useState();
  let [getPage, SetPage] = useState(1);

  

  async function GetItemsValue(){    
    await fetch(`https://www.sgcarena.com/wp-json/wp/v2/posts/?per_page=12&&page=${getPage}`)
      .then( response => {
        SetPostList(response.headers.get('X-WP-Total'))
        return response.json()
      })
      .then(data => {
        SetPage((preVal)=> preVal+=1)
        SetItems((PreVal)=> [...PreVal,...data])
        SetCurrentPostList(getItems.length);
        console.log(getCurrentPostList);
        }
    )
  }

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight) {
      return;
    }
    if(getPostList===getCurrentPostList){
      console.log('All Loaded')
    }else{
      GetItemsValue()
    }
    
  };

  useEffect(()=>{
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  

  return(
    <main className={style.home} style={{ backgroundImage: `url(${background})` }}>
      <Header/>
      <div className="main m-auto px-2.5 py-20 container">
          <div className="flex lg:flex-row flex-col-reverse items-center gap-5">
            <div className="text-left lg:w-6/12 w-full">
              <h1 className="lg:text-7xl lg:mb-12 font-bold mb-8 text-5xl"> A small business is only as good </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Dolor a adipiscing convallis pretium accumsan sit.
              </p>
              <SearchBox/>
            </div>
            <div className="text-right lg:w-6/12 :w-full">
              <img src={require("../../img/sgcarena-banner.png")} alt="sgcarena-banner"/>
            </div>
          </div>
          <div className="m-auto lg:mt-24 lg:mb-36 mt-8 mb-12">
            <img className="w-full" src={require("../../img/box.png")} alt="box"/>
          </div>
          <div className="product-list">
            <h2 className="text-left font-bold">Latest BUSSID Vehicle Mod</h2>
            <Card items={getItems}/>
          </div>
      </div>
    </main>
  )
}

export default HomePage;