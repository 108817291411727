import React, {useState} from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-solid-svg-icons'
import styles from "./SinglePage.module.css";
import Header from "../../components/Header/Header";

const SinglePage = () => {
  const [ShowText, HideTxt] = useState(false);

  const copyPassword = (getPass) =>{
    navigator.clipboard.writeText(getPass);
    HideTxt(true);
    setTimeout(()=>{
      HideTxt(false);
    },500)
  }


  return(
    <main className={`${styles.single}`}>
      <Header/>
      <section className="pt-16 container m-auto p-2.5">
        <h1 className="text-4xl font-bold">Nissan Vision Gran Turismo Mod BUSSID</h1>
        <div className="lg:flex lg:flex-wrap mt-12 lg:flex-row flex-col">
          <div className="lg:basis-9/12 lg:pr-2.5">
            <img className={`${styles.single_img}`} src="https://i0.wp.com/www.sgcarena.com/wp-content/uploads/2023/05/SGCA_IMG-27052023-150308.jpg?w=1200&ssl=1" height="500px" alt="Nissan Vision"/>
          </div>
          <div className="lg:basis-3/12 lg:pl-2.5 lg:mt-0 mt-5">
            <div className={`${styles.singleCard} p-6 flex flex-wrap flex-col justify-between`}>
              <div className="header">
                <p className="flex leading-7 mb-2">Name:<span> Nissan Vision Gran Turismo</span></p>
                <p className="flex leading-7 mb-2">Game:<span> Bus Simulator Indonesia (BUSSID)</span></p>
                <p className="flex leading-7 mb-2">Author:<span> MAH Channel</span></p>
                <p className="flex leading-7 mb-2">Credit:<span> Gold evil</span></p>
                <p className="flex leading-7 mb-2">Price:<span> Free</span></p>
                <p className="flex leading-7 mb-2">Size:<span> 4 MB</span></p>
              </div>
              <div className={`${styles.footer}`}>
                <button className="w-full py-3 rounded-full text-white font-bold mb-5">Download</button>
                <ul>
                  <li>Bus Simulator Indonesia</li>
                  <li>Vehicles</li>
                  <li>Vehicles</li>
                  <li>Vehicles</li>
                  <li>Vehicles</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-16 container m-auto flex lg:flex-row flex-col p-2.5">
        <div className={`basis-10/12 lg:pr-2.5`}>
          <div className={`${styles.tab_title} flex text-sm font-medium`}>
            <span className="mr-1.5 py-1.5 px-3 rounded-3xl">Mod Information</span>
            <span className="ml-1.5 py-1.5 px-3 rounded-3xl">Mod File</span>
          </div>
          <div className={`${styles.table} p-5 mt-9 rounded-md`}>
            <table className={`w-full`}>
              <tbody>
                <tr>
                  <td>Model</td>
                  <td>Man Lions Classic</td>
                </tr>
                <tr>
                  <td>Author</td>
                  <td>Hanzoo Mod</td>
                </tr>
                <tr>
                  <td>Credit</td>
                  <td>MinatoNamikaze</td>
                </tr>
                <tr>
                  <td>Game</td>
                  <td>Bus Simulator Indonesia</td>
                </tr>
                <tr>
                  <td>Compatible</td>
                  <td>V3.7.1</td>
                </tr>
                <tr>
                  <td>Category</td>
                  <td>Vehicles</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>Bus</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td>File Size</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>File Type</td>
                  <td>.zip</td>
                </tr>
                <tr>
                  <td>Password</td>
                  <td className="relative">
                    123 
                    <FontAwesomeIcon className="float-right cursor-pointer" icon={faClipboard} onClick={() => copyPassword('someText')} /> 
                    {ShowText && <span className={`${styles.copid_txt} absolute top-5`}>Copid!</span>}
                  </td>
                </tr>
                <tr>
                  <td>Test 1</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>Test 2</td>
                  <td>2</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="basis-3/12 lg:pl-2.5 lg:block flex justify-between flex-wrap">
          <div className={`${styles.card}`} >
            <Link to={'post'}>
            <img className="w-full" src={require("../../img/demo-img.png")} alt="demo-img" />
            <div className="mt-4">
              <h3 className="font-bold">Hellod</h3>
              <div className={`${styles.card_meta_info} flex justify-between mt-0.5`}>
                <p className="text-sm"><span style={{color:"#afcaf499"}}>by</span></p>
                <div className="flex items-center">
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/download-cloud.png")} alt="download-icon"/>
                    <span className="download-count text-sm ml-0.5">241</span>
                  </div>
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/thumbs-up.png")} alt="thumbsup-icon"/>
                    <span className="like-count text-sm ml-0.5">241</span>
                  </div>
                </div>
              </div>
              <div className={`${styles.cat_info} border-t mt-2 pt-2`}>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
              </div>
            </div>
            </Link>
          </div>

          <div className={`${styles.card}`} >
            <Link to={'post'}>
            <img className="w-full" src={require("../../img/demo-img.png")} alt="demo-img" />
            <div className="mt-4">
              <h3 className="font-bold">Hellod</h3>
              <div className={`${styles.card_meta_info} flex justify-between mt-0.5`}>
                <p className="text-sm"><span style={{color:"#afcaf499"}}>by</span></p>
                <div className="flex items-center">
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/download-cloud.png")} alt="download-icon"/>
                    <span className="download-count text-sm ml-0.5">241</span>
                  </div>
                  <div className="flex items-center mx-0.5">
                    <img src={require("../../img/thumbs-up.png")} alt="thumbsup-icon"/>
                    <span className="like-count text-sm ml-0.5">241</span>
                  </div>
                </div>
              </div>
              <div className={`${styles.cat_info} border-t mt-2 pt-2`}>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
                  <p className={`${styles.cat_list}`}>vhickle</p>
              </div>
            </div>
            </Link>
          </div>
        </div> 
      </section>

    </main>
  )
}

export default SinglePage;