import React,{useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import styles from "./Header.module.css";


const Header = () => {
  const [visible1, setvisible1] = useState(false);
  const [visible2, setvisible2] = useState(false);
  const [menuVisiale, setMenuVisiale] = useState(false);
  const handleClick1 = () =>{
    setvisible2(false)
    setvisible1(!visible1)
  }
  const handleClick2 = () =>{
    setvisible1(false)
    setvisible2(!visible2)
  }
  const handleMenu = () =>{
    setMenuVisiale(!menuVisiale)
  }
  return (
    <header className="md:w-full container m-auto	p-2.5">
      <div className="flex flex-wrap">
        <div className="lg:w-1/4 md:w-3/6 w-2/4">
           <a className="md:text-left text-center"	 href="/">
              <img src={require("../../img/sgcarena-logo.png")} alt="sgcarena"/>
           </a>
        </div>
        <div className="lg:w-2/4 md:w-1/6 w-2/4 lg:order-none md:order-2 flex lg:justify-center justify-end items-center relative"> 
          <nav className="desktop-navigation hidden lg:block">
            <ul className="flex">
              <li className={`py-3 px-3.5`}> <a href="/">Home</a></li>
              <li className={`py-3 px-3.5`}> <a href=""> News </a></li>
              <li className={`py-3 px-3.5	relative ${styles.desktop_has_submenu}`} >
              <a href=""> BUSSID MOD </a>  
              <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              <ul className={`absolute bg-white text-black text-left top-12 w-36 text-sm ${styles.desktop_dropdown_list} transition-all`}>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href=""> Vehicles </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href=""> Maps </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href=""> Obb </a></li>
                  <li className="px-4 py-2.5 hover:bg-slate-100"> <a href=""> BUSSID Livery </a></li>
                </ul>
              </li>
              <li className={`py-3 px-3.5 relative ${styles.desktop_has_submenu}`} >
              <a href="">
                Mod Type
               </a> 
              <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                <ul className={`absolute bg-white text-black text-left top-12 w-36 text-sm ${styles.desktop_dropdown_list} transition-all`}>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href=""> Add-on </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href="">Bus </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href="">Car </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href="">Mountain </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href="">Off Road </a></li>
                  <li className="px-4 py-2.5 border-b hover:bg-slate-100"> <a href="">Pickup </a></li>
                  <li className="px-4 py-2.5 border-b"> <a href="">Replaced</a></li>
                  <li className="px-4 py-2.5 hover:bg-slate-100"><a href="">Truck</a></li>
                </ul>
              </li>
            </ul>
          </nav>
          <div onClick={handleMenu} className={`${styles.navbtn} w-10 border-2 border-white h-8 rounded flex flex-wrap items-center lg:hidden`}>
            <div className="h-0.5 w-full bg-white"></div>
            <div className="h-0.5 w-full bg-white"></div>
            <div className="h-0.5 w-full bg-white"></div>
          </div>


          {menuVisiale &&
          <nav className={`${styles.mobile_navigation} fixed min-h-screen w-screen z-10`}>
            <div className="text-left">
              <FontAwesomeIcon icon={faXmark} className="text-white p-4" onClick={handleMenu} />
            </div>
            <ul className="text-left">
              <li className={`py-3 px-3.5`}> <a href="/">Home</a></li>
              <li className={`py-3 px-3.5`}> <a href=""> News </a></li>
              <li className={`py-3 px-3.5`} onClick={handleClick1}>
              <a href=""> BUSSID MOD </a>  
              <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              { visible1 &&
              <ul className={`text-left top-12 w-36 text-sm transition-all`}>
                  <li className="p-4"> <a href=""> Vehicles </a></li>
                  <li className="p-4"> <a href=""> Maps </a></li>
                  <li className="p-4"> <a href=""> Obb </a></li>
                  <li className="p-4"> <a href=""> BUSSID Livery </a></li>
                </ul>
              }
              </li>
              <li className={`py-3 px-3.5`} onClick={handleClick2}>
              <a href="">
                Mod Type
               </a> 
              <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
              {visible2 &&
                <ul className={`text-left top-12 w-36 text-sm} transition-all`}>
                  <li className="p-4"> <a href=""> Add-on </a></li>
                  <li className="p-4"> <a href="">Bus </a></li>
                  <li className="p-4"> <a href="">Car </a></li>
                  <li className="p-4"> <a href="">Mountain </a></li>
                  <li className="p-4"> <a href="">Off Road </a></li>
                  <li className="p-4"> <a href="">Pickup </a></li>
                  <li className="p-4"> <a href="">Replaced</a></li>
                  <li className="p-4"><a href="">Truck</a></li>
                </ul>
              }
              </li>
            </ul>
          </nav>
          }
        </div>
        <div className="lg:w-1/4 md:w-2/6 lg:flex md:flex justify-end items-center hidden">
          <div className="header_right_btn">
            <a className="singup_btn mx-2 font-bold" href="">Sing up</a>
            <a className={`bg-sky-500/100 mx-2 font-bold rounded-lg px-5 py-2.5`} href="">Login</a>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header;